import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmComponent } from '../../components/modal/confirm/confirm.component';
import { UserApiService } from '../../services/api/user-api.service';
import { SpinnerService } from '../../services/pages/spinner.service';
import { BaseModalPageDirective } from '../base-modal-page';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: [ './privacy-policy.component.scss' ],
})
export class PrivacyPolicyComponent extends BaseModalPageDirective<PrivacyPolicyComponent, boolean> implements OnInit {
  public readonly readOnly: boolean;
  public organizationPolicy: string | null = null;
  public bookkerPolicy: string | null = null;
  public acceptedCustomPolicy: boolean = false;
  public acceptedBookkerPolicy: boolean = false;
  public organizationResponse: boolean | undefined;
  public bookkerResponse: boolean | undefined;
  public saving = false;

  public constructor(
    matDialogRef: MatDialogRef<PrivacyPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) data: PrivacyPolicyOptions | undefined,
    private matDialog: MatDialog,
    private spinnerService: SpinnerService,
    private userApi: UserApiService,
  ) {
    super(matDialogRef);

    this.readOnly = !!data && data.readOnly;
  }

  public ngOnInit(): void {
    this.userApi.getUser().subscribe(user => {
      if (user.organization != null && user.organization.modules != null) {
        this.organizationPolicy = user.organization.modules.privacyPolicyConfig?.privacyPolicyText ?? null;
        this.bookkerPolicy = user.organization.modules.bookkerPrivacyPolicy?.privacyPolicyText ?? null;
        this.acceptedCustomPolicy = user.acceptedCustomPolicy;
        this.acceptedBookkerPolicy = user.acceptedBookkerPolicy;
      }
    });
  }

  public exit(): void {
    const dialog = ModalConfirmComponent.open(this.matDialog, {
      title: 'bkwa.privacyPolicy.confirmExit.title',
      text: null,
      acceptButton: 'bkwa.privacyPolicy.confirmExit.accept',
      cancelButton: 'bkwa.privacyPolicy.confirmExit.cancel',
    });
    this.observable(dialog.beforeClosed()).subscribe(result => {
      if (result) {
        this.close(false);
      }
    });
  }

  public isAllPolicyAccepted(): boolean {
    const isBookkerPolicyAccepted = this.acceptedBookkerPolicy || this.bookkerResponse;
    const isOrganizationPolicyAccepted = this.organizationPolicy == null || this.acceptedCustomPolicy || this.organizationResponse;
    return (isBookkerPolicyAccepted && isOrganizationPolicyAccepted) ?? false;
  }

  public accept(): void {
    this.saving = true;
    this.userApi
      .updatePolicy(this.bookkerResponse, this.organizationResponse)
      .then(() => {
        this.close(true);
      })
      .finally(() => {
        this.saving = false;
      });
  }
}

export interface PrivacyPolicyOptions {
  readOnly: boolean;
}
