/* eslint-disable */
export default {
  "dirty": false,
  "raw": "v1.14.5-1-g7e357619",
  "hash": "g7e357619",
  "distance": 1,
  "tag": "v1.14.5",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.14.5",
    "major": 1,
    "minor": 14,
    "patch": 5,
    "prerelease": [],
    "build": [],
    "version": "1.14.5"
  },
  "suffix": "1-g7e357619",
  "semverString": "1.14.5+1.g7e357619"
};
