import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeCa from '@angular/common/locales/ca';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeCaExtra from '@angular/common/locales/extra/ca';
import localeElExtra from '@angular/common/locales/extra/el';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import localePtExtra from '@angular/common/locales/extra/pt';
import localePtPtExtra from '@angular/common/locales/extra/pt-PT';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import localePtPt from '@angular/common/locales/pt-PT';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { CookieService } from 'ngx-cookie-service';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalModule } from './components/modal/modal.module';
import { Theme } from './model/theme';
import { PrivacyPolicyModule } from './pages/privacy-policy/privacy-policy.module';
import { CustomDatePickerAdapter } from './services/adapters/CustomDatePickerAdapter';
import { SessionHttpInterceptorService } from './services/api/common/http-interceptor.service';
import { GlobalErrorHandler } from './services/global-error-handler';
import { LanguageProvider, LanguageService } from './services/pages/language.service';
import { SharedModule } from './shared.module';

registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeEnGb, 'en-GB', localeEnGbExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localePt, 'pt', localePtExtra);
registerLocaleData(localePtPt, 'pt-PT', localePtPtExtra);
registerLocaleData(localeIt, 'it', localeItExtra);
registerLocaleData(localeEl, 'el', localeElExtra);
registerLocaleData(localeCa, 'ca', localeCaExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);

@NgModule({
  declarations: [ AppComponent ],
  bootstrap: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ModalModule,
    PrivacyPolicyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [ HttpClient ],
      },
    }),
    QuillModule.forRoot({
      sanitize: true,
      modules: {
        toolbar: [
          [ 'bold', 'italic', 'underline', 'strike' ],
          [ { color: [] }, { background: [] } ],
          [ { font: [] } ],
          [ { size: [ 'small', false, 'large', 'huge' ] } ],
          [ { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' } ],
          [ { align: [] } ],
          [ 'clean' ],
          [ 'link' ],
        ],
        keyboard: {
          bindings: {
            tab: false,
          },
        },
      },
      placeholder: '',
    }),
    MaterialCssVarsModule.forRoot({
      isDarkTheme: false,
      primary: Theme.defaultPrimary,
      accent: Theme.defaultAccent,
      isAutoContrast: false,
    }),
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionHttpInterceptorService,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: CustomDatePickerAdapter,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: LOCALE_ID,
      deps: [ LanguageService ],
      useClass: LanguageProvider,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    iconRegistry.registerFontClassAlias('filled', 'material-symbols-outlined-filled');
  }
}
