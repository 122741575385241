import { NgModule } from '@angular/core';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { SharedModule } from '../../shared.module';


@NgModule({
  declarations: [
    PrivacyPolicyComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class PrivacyPolicyModule { }
