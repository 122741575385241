import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoLoggedInGuard } from './guards/no-logged-in.guard';
import { LoggedInGuard } from './guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule),
    canActivate: [ NoLoggedInGuard ],
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then(module => module.LogoutModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
    canActivate: [ LoggedInGuard ],
  },
  {
    path: 'bookings',
    loadChildren: () => import('./pages/bookings/bookings.module').then(module => module.BookingsModule),
    canActivate: [ LoggedInGuard ],
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(module => module.SearchModule),
    canActivate: [ LoggedInGuard ],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(module => module.ProfileModule),
    canActivate: [ LoggedInGuard ],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    }),
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
