import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/api/common/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  public constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isLoggedInObservable().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          const pendingRedirect = this.authService.getAndRemovePendingRedirect();
          if (pendingRedirect != null && !pendingRedirect.startsWith('/login')) {
            return this.router.parseUrl(pendingRedirect);
          } else {
            return true;
          }
        } else {
          this.authService.saveRedirectAfterLogin(state.url);
          return this.router.parseUrl('/login');
        }
      }),
    );
  }
}
