<header>
  <h2>{{ 'bkwa.privacyPolicy.title' | translate }}</h2>
  <div class="right">
    @if (readOnly) {
      <button mat-icon-button type="button" (click)="cancel()" [matTooltip]="'bkwa.common.close' | translate">
        <mat-icon>close</mat-icon>
      </button>
    }
    @if (!readOnly) {
      <button mat-button (click)="exit()">{{ 'bkwa.privacyPolicy.exit' | translate }}</button>
    }
  </div>
</header>
<div class="container">
  @if (bookkerPolicy != null && (readOnly || !acceptedBookkerPolicy)) {
    <article>
      <h3 class="title">{{ 'bkwa.privacyPolicy.bookker.title' | translate }}</h3>
      <div class="policy" [innerHTML]="bookkerPolicy"></div>
    </article>
  }
  @if (organizationPolicy != null && (readOnly || !acceptedCustomPolicy)) {
    <article>
      <h3 class="title">{{ 'bkwa.privacyPolicy.organization.title' | translate }}</h3>
      <div class="policy" [innerHTML]="organizationPolicy"></div>
    </article>
  }
  @if (!readOnly) {
    <form>
      <div>
        @if (!acceptedBookkerPolicy) {
          <mat-checkbox name="bookker" [(ngModel)]="bookkerResponse" required>
            {{ 'bkwa.privacyPolicy.bookker.accept' | translate }}
          </mat-checkbox>
        }
        @if (organizationPolicy != null && !acceptedCustomPolicy) {
          <mat-checkbox name="organization" [(ngModel)]="organizationResponse" required>
            {{ 'bkwa.privacyPolicy.organization.accept' | translate }}
          </mat-checkbox>
        }
      </div>
      <div class="buttons">
        <button type="submit" mat-flat-button color="primary" (click)="accept()" [disabled]="saving || !isAllPolicyAccepted()">
          {{ 'bkwa.privacyPolicy.accept' | translate }}
        </button>
      </div>
    </form>
  }
</div>
