import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostListener, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Numberify, RGBA } from '@ctrl/tinycolor';
import { TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { filter } from 'rxjs/operators';
import { constants } from '../environments/constants';
import { environment } from '../environments/environment';
import { revealAnimation, routerAnimation } from './animations';
import { ModalConfirmComponent } from './components/modal/confirm/confirm.component';
import { Timezone } from './model/date-time';
import { ReadonlyDate } from './model/dates';
import { Theme } from './model/theme';
import { BasePageDirective } from './pages/base-page';
import { PrivacyPolicyComponent, PrivacyPolicyOptions } from './pages/privacy-policy/privacy-policy.component';
import { AuthService } from './services/api/common/auth.service';
import { FilesApiService } from './services/api/files-api.service';
import { SessionApiService } from './services/api/session-api.service';
import { UserApiService } from './services/api/user-api.service';
import { GoogleService } from './services/login/google.service';
import { MicrosoftService } from './services/login/microsoft.service';
import { CookiesNoticeService } from './services/pages/cookies-notice.service';
import { LanguageService } from './services/pages/language.service';
import { NotificationService } from './services/pages/notification.service';
import { SpinnerService } from './services/pages/spinner.service';
import { ThemeService } from './services/pages/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  animations: [ routerAnimation, revealAnimation ],
})
export class AppComponent extends BasePageDirective implements OnInit {
  public theme: Theme | null = null;
  public isAuthenticated = false;
  public spinner = false;

  public readonly version = constants.version;
  public readonly gitVersion = constants.gitVersion;

  public title: string;
  private readonly defaultTitle: string;

  public readonly navigation: readonly NavigationItem[] = [
    {
      link: 'home',
      label: 'bkwa.menu.home',
      icon: 'home',
    },
    {
      link: 'bookings',
      label: 'bkwa.menu.booking',
      icon: 'today',
    },
    {
      link: 'search',
      label: 'bkwa.menu.search',
      icon: 'search',
    },
    {
      link: 'profile',
      label: 'bkwa.menu.profile',
      icon: 'account_circle',
    },
  ];
  public currentMenu: string | null = null;
  public loaded = false;
  public readonly fullScreenEnabled = document.fullscreenEnabled;
  public readonly isProduction = environment.production;

  public constructor(
    private router: Router,
    private authService: AuthService,
    private userApi: UserApiService,
    private sessionApi: SessionApiService,
    private filesApi: FilesApiService,
    private themeService: ThemeService,
    private spinnerService: SpinnerService,
    private cookiesNoticeService: CookiesNoticeService,
    private matDialog: MatDialog,
    private clipboard: Clipboard,
    private microsoftLoginService: MicrosoftService,
    private googleLoginService: GoogleService,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private titleService: Title,
    private materialCssVarsService: MaterialCssVarsService,
  ) {
    super();

    this.title = this.defaultTitle = this.titleService.getTitle();

    this.translate.addLangs(LanguageService.LANGUAGES);
    this.translate.setDefaultLang(LanguageService.LANGUAGES[0]);
    this.languageChangeListener();
    this.observable(this.languageService.getObservable()).subscribe(language => {
      this.translate.use(language);
      this.dateAdapter.setLocale(language);
      document.documentElement.lang = language;

      if (this.loaded) {
        const { url } = this.router;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([ url ]));
      }
    });
  }

  public ngOnInit(): void {
    const loginInformation = this.authService.getLoginInformation();
    if (loginInformation.isExternal()) {
      (async () => {
        this.spinnerService.show(15000);
        try {
          const data = await this.sessionApi.checkDomain(loginInformation.username);
          if (!data.config) {
            throw new Error('No config for the provider ' + data.authType);
          }
          if (data.authType === 'MICROSOFT') {
            this.microsoftLoginService.init(data.config, loginInformation.username);
            return;
          } else if (data.authType === 'GOOGLE') {
            await this.googleLoginService.logIn(data.config, loginInformation.username);
            return;
          }
        } catch (error: unknown) {
          console.error(error);
          this.logout();
        }
        this.spinnerService.hide();
      })();
    }

    this.observable(this.authService.isLoggedInObservable()).subscribe(isAuthenticated => {
      if (isAuthenticated && !this.isAuthenticated) {
        this.userApi.getResources(); // precargar para que se aplique el tema
        this.userApi.getUser().subscribe(user => {
          if (user.acceptedBookkerPolicy) {
            if (user.acceptedCustomPolicy || !user.organization?.modules?.isCustomPolicy()) {
              return;
            }
          }

          const dialog = ModalConfirmComponent.open(this.matDialog, {
            title: 'bkwa.privacyPolicy.welcome.title',
            text: 'bkwa.privacyPolicy.welcome.text',
            acceptButton: 'bkwa.privacyPolicy.welcome.button',
            cancelButton: null,
          });
          this.observable(dialog.afterClosed()).subscribe(() => {
            const dialogFullModal = this.openFullDialog<void>(this.matDialog, PrivacyPolicyComponent, undefined, false);
            this.observable(dialogFullModal.afterClosed()).subscribe(result => {
              if (!result) {
                this.logout();
              }
            });
          });
        });
      }
      this.isAuthenticated = isAuthenticated;
    });

    let loadingDate: ReadonlyDate | null = new Date();
    this.observable(this.router.events)
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(event => {
        if (loadingDate != null) {
          console.info('Loading finished', Date.now() - loadingDate.getTime());
          loadingDate = null;
        }
        this.loaded = true;
        this.currentMenu = event.urlAfterRedirects.split('?')[0].split('/')[1] ?? null;
        this.setPageTitle();
      });

    this.observable(this.themeService.get$()).subscribe(theme => {
      this.theme = theme;
      if (this.theme !== Theme.default && theme.logoApps != null) {
        this.filesApi.getFile(theme.logoApps).then(img => {
          theme.setLogoAppsData(img);
        });
      }

      // apply this theme
      const primary = theme.getPrimaryColor();
      const secondary = theme.getTertiaryColor();
      if (primary != null && secondary != null) {
        AppComponent.setCssVariable('--primary', '#' + primary.toHex());
        AppComponent.setCssVariable('--primary-hex', primary.toRgb());
        AppComponent.setCssVariable('--dark-blue', '#' + secondary.toHex());
        this.materialCssVarsService.setPrimaryColor(primary.toHexString());
      } else {
        for (const property of Array.from(window.document.documentElement.style)) {
          if (property.startsWith('--')) {
            AppComponent.setCssVariable(property, null);
          }
          this.materialCssVarsService.setPrimaryColor(Theme.defaultPrimary);
          this.materialCssVarsService.setAccentColor(Theme.defaultAccent);
          this.materialCssVarsService.setWarnColor(Theme.defaultWarn);
        }
      }
    });

    this.observable(this.spinnerService.getObservable()).subscribe(status => {
      this.spinner = status;
    });

    if (!this.cookiesNoticeService.isAccepted()) {
      this.notificationService.info('bkwa.cookies.notice', true, undefined, -1).then(snackbar => {
        this.observable(snackbar.afterDismissed()).subscribe(() => {
          this.cookiesNoticeService.accept();
        });
      });
    }
  }

  public prepareRoute(outlet: RouterOutlet): string | undefined {
    return outlet.activatedRouteData?.['title'];
  }

  public isActive(item: NavigationItem): boolean {
    return this.currentMenu === item.link;
  }

  public enterFullScreen(): void {
    window.document.documentElement.requestFullscreen();
  }

  public exitFullScreen(): void {
    window.document.exitFullscreen();
  }

  public isFullScreen(): boolean {
    return window.document.fullscreenElement != null;
  }

  public openPrivacyPolicy(): void {
    this.openFullDialog<PrivacyPolicyOptions>(this.matDialog, PrivacyPolicyComponent, {
      readOnly: true,
    });
  }

  public getHelpCenterLink(): string {
    if ([ 'es', 'ca' ].includes(this.languageService.getSimple())) {
      return 'https://help.bookkercorp.com/';
    } else {
      return 'https://help.bookkercorp.com/en/';
    }
  }

  public getLegalNoticeLink(): string {
    const urls: Record<string, string> = {
      es: 'https://www.bookkercorp.com/aviso-legal/',
      ca: 'https://www.bookkercorp.com/aviso-legal/',
      en: 'https://www.bookkercorp.com/en/legal-notice/',
    };
    return urls[this.languageService.getSimple()] || urls['en'];
  }

  public logout(): void {
    this.router.navigate([ '/logout' ]);
  }

  public copyVersion(): void {
    const text =
      `Bookker webapp v${this.version} (build ${this.gitVersion.raw}),` +
      ` at ${window.location.href} (${new Date()} - ${Timezone.guessTimezone()}).` +
      ` Browser: ${window.navigator?.userAgent}. Languages: ${window.navigator?.languages}`;
    if (this.clipboard.copy(text)) {
      this.notificationService.success('bkwa.menu.version-details-copied', true);
    }
  }

  private setPageTitle(): void {
    let lastChild = this.router.routerState.snapshot.root;
    let title: string | null = null;
    while (lastChild.children.length !== 0) {
      lastChild = lastChild.children[0];
      if (typeof lastChild.data['title'] === 'string') {
        title = lastChild.data['title'];
      }
    }
    if (title != null) {
      this.observable(this.translate.get(title)).subscribe(titleI18n => {
        this.title = titleI18n + ' - ' + this.defaultTitle;
        this.titleService.setTitle(this.title);
      });
    } else {
      this.title = this.defaultTitle;
      this.titleService.setTitle(this.title);
    }
  }

  @HostListener('click')
  @HostListener('document:keydown')
  public clickListener(): void {
    this.authService.scheduleLogout();
  }

  @HostListener('window:languagechange')
  public languageChangeListener(): void {
    const languages = [ this.translate.getBrowserCultureLang(), this.translate.getBrowserLang() ].filter((lang): lang is string => lang !== undefined);
    this.languageService.set(...languages);
    this.setPageTitle();
  }

  private static setCssVariable(name: string, value: string | Numberify<RGBA> | null): void {
    if (value != null && typeof value === 'object') {
      value = [ value.r, value.g, value.b ].join(',');
    }
    window.document.documentElement.style.setProperty(name, value);
  }
}

interface NavigationItem {
  link: string;
  label: string;
  icon: string;
}
