import { Injectable } from '@angular/core';
import { BaseApiService, FullUrlBuilder } from './common/base-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constants } from '../../../environments/constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorsApiService extends BaseApiService {
  private pendingErrors: LoggableError[] = [];

  public constructor(httpClient: HttpClient) {
    super(httpClient);

    window.addEventListener('unload', () => {
      this.sendErrors();
    });
  }

  public insertDelayed(error: LoggableError): void {
    this.pendingErrors.push(error);
    window.setTimeout(() => {
      this.sendErrors();
    }, 500);
  }

  private insert(errors: readonly LoggableError[]): Promise<void> {
    if (environment.production) {
      const headers = new HttpHeaders({
        'App-Version': 'WebApp/' + constants.version + '_' + constants.gitVersion.hash,
        Authorization: 'Basic ' + btoa('bookker:bookker_bugs_123'),
      });
      return this._post<void>(FullUrlBuilder.create('https://develop.bookkercorp.com/apiBugReport/v1/reportBug'), errors, headers, false);
    } else {
      return Promise.resolve();
    }
  }

  private sendErrors(): void {
    if (this.pendingErrors.length !== 0) {
      this.insert(this.pendingErrors).catch(error => {
        console.warn('Error al guardar traza del error', error);
      });
      this.pendingErrors = [];
    }
  }
}

type LoggableErrorLevel = 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR';

export class LoggableError {
  private static totalCount = 0;

  private readonly count: number;

  public constructor(private readonly error: Error,
                     private readonly userEmail: string | null,
                     private readonly level: LoggableErrorLevel,
  ) {
    this.count = ++LoggableError.totalCount;
  }

  public toJSON(): object {
    return {
      email_user: this.userEmail,
      error_message: JSON.stringify({
        message: this.error.message,
        name: this.error.name,
        stack: this.error.stack,
        url: window.location.href,
        count: this.count,
      }),
      tag_error: null,
      level: this.level,
      environment: environment.name,
    };
  }
}
