import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { constants } from '../../../../environments/constants';
import { ModalConfirmComponent } from '../../../components/modal/confirm/confirm.component';
import { LanguageService } from '../../pages/language.service';
import { ApiCustomError, ApiErrorGeneric, ApiErrorNoConnection } from './api-error';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SessionHttpInterceptorService implements HttpInterceptor {
  private lastLogoutError = 0;

  public constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private matDialog: MatDialog,
    private languageService: LanguageService,
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = req.headers.append('user-language', this.languageService.get()).append('app-version', 'WEBAPP/' + constants.version);
    if (req.withCredentials) {
      const csrfToken = this.cookieService.get('CSRF-TOKEN');
      if (csrfToken) {
        headers = headers.append('X-CSRF-TOKEN', csrfToken);
      }
    }

    if (![ null, 'NATIVE' ].includes(this.authService.getLoginInformation().loginType)) {
      const externalToken = this.authService.getExternalTokenIfNeeded();
      if (externalToken != null) {
        headers = headers.append('user-access-token', externalToken);
      } else if (this.authService.getExternalToken() === null) {
        if (!req.url.endsWith('validateuser')) {
          console.warn('External token not available', req.url);
        }
      }
    }

    return next
      .handle(
        req.clone({
          headers,
        }),
      )
      .pipe(
        map(response => {
          if (response instanceof HttpResponse) {
            // procesar errores que llegan como "200 OK"
            if (ApiCustomError.isError(response.body)) {
              console.warn('Error enviado con HTTP 200 OK');
              throw new ApiCustomError(response.body);
            }
          }
          return response;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCode.Forbidden) {
              this.handleLogout();
            } else if (ApiCustomError.isError(error.error)) {
              throw new ApiCustomError(error.error, error);
            }
          }
          throw error;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              throw new ApiErrorNoConnection();
            } else {
              throw new ApiErrorGeneric(undefined, error.status);
            }
          } else if (error instanceof ApiCustomError) {
            if (error.code === null) {
              throw new ApiErrorGeneric(error.message);
            }
          } else {
            console.error('API error', error);
            throw new ApiErrorGeneric(undefined);
          }
          throw error;
        }),
      );
  }

  private handleLogout(): boolean {
    const recentLogoutError = Date.now() - this.lastLogoutError < 15000;
    if (this.authService.logOut(true) || recentLogoutError) {
      if (!recentLogoutError) {
        const dialog = ModalConfirmComponent.open(this.matDialog, {
          title: 'bkwa.login.sessionExpired.title',
          text: 'bkwa.login.sessionExpired.text',
          cancelButton: null,
        });
        dialog.afterClosed().subscribe(() => {
          this.lastLogoutError = 0;
        });
        this.lastLogoutError = Date.now();
      }
      return true;
    } else {
      return false;
    }
  }
}
