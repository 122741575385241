import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookiesNoticeService {
  private static readonly STORAGE_KEY = 'bkwa-cookies-notice';
  private static readonly VALUE = '1';

  public constructor(private cookieService: CookieService) {
  }

  public isAccepted(): boolean {
    return this.cookieService.get(CookiesNoticeService.STORAGE_KEY) === CookiesNoticeService.VALUE;
  }

  public accept(): void {
    this.cookieService.set(CookiesNoticeService.STORAGE_KEY, CookiesNoticeService.VALUE, {
      expires: 90,
      secure: window.location.protocol === 'https:',
    });
  }
}
