<div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop app-loading" [class.cdk-overlay-backdrop-showing]="spinner">
  <svg class="spinner" viewBox="25 25 50 50">
    <title>{{ 'bkwa.common.loading' | translate }}</title>
    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
  </svg>
</div>
<mat-sidenav-container autosize class="transition-loading" [class.loaded]="loaded">
  <mat-sidenav #sidenav>
    <mat-nav-list>
      @for (item of navigation; track item) {
        <mat-list-item class="menu-item" [class.active]="isActive(item)">
          <a [routerLink]="['/' + item.link]" (click)="sidenav.close()">
            <mat-icon>{{ item.icon }}</mat-icon>
            {{ item.label | translate }}
          </a>
        </mat-list-item>
      }
      @if (fullScreenEnabled) {
        @if (!isFullScreen()) {
          <mat-list-item class="menu-item">
            <a (click)="enterFullScreen(); sidenav.close()">
              <mat-icon>fullscreen</mat-icon>
              {{ 'bkwa.common.fullscreen' | translate }}
            </a>
          </mat-list-item>
        }
        @if (isFullScreen()) {
          <mat-list-item class="menu-item">
            <a (click)="exitFullScreen(); sidenav.close()">
              <mat-icon>fullscreen_exit</mat-icon>
              {{ 'bkwa.common.normalscreen' | translate }}
            </a>
          </mat-list-item>
        }
      }
    </mat-nav-list>
  </mat-sidenav>
  <header>
    <mat-toolbar>
      <div class="container-fluid">
        @if (isAuthenticated) {
          <button mat-icon-button class="menu left d-md-none" (click)="sidenav.toggle()" [matTooltip]="'bkwa.common.menu' | translate">
            <mat-icon>menu</mat-icon>
          </button>
        }
        <h1>
          <a routerLink="/">
            @if (theme != null && theme.getLogo(); as logo) {
              <img [src]="logo" alt="Bookker" @revealAnimation />
            }
            <span class="visually-hidden">{{ title }}</span>
          </a>
        </h1>
        <div class="menu right">
          <button mat-icon-button type="button" [matMenuTriggerFor]="optionsMenu" [matTooltip]="'bkwa.common.options' | translate">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #optionsMenu="matMenu">
            @if (isAuthenticated) {
              <a mat-menu-item (click)="logout()">
                {{ 'bkwa.menu.logout' | translate }}
              </a>
            }
            <a mat-menu-item [href]="getHelpCenterLink()" target="_blank" rel="noopener">
              {{ 'bkwa.menu.helpCenter' | translate }}
            </a>
            @if (isAuthenticated) {
              <a mat-menu-item (click)="openPrivacyPolicy()">
                {{ 'bkwa.menu.conditions' | translate }}
              </a>
            }
            <a mat-menu-item [href]="getLegalNoticeLink()" target="_blank" rel="noopener">
              {{ 'bkwa.menu.legalNotice' | translate }}
            </a>
            @if (version) {
              <a mat-menu-item (click)="copyVersion()" class="text-muted">
                Webapp v{{ version }}
                @if (!isProduction) {
                  <small>
                    (
                    @if (gitVersion.hash) {
                      <span class="font-monospace" [title]="gitVersion.raw">{{ gitVersion.hash | slice: 0 : 5 }}…</span>
                    }
                    @if (gitVersion.dirty) {
                      *
                    }
                    )
                  </small>
                }
              </a>
            }
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>
  </header>
  <div class="content-wrapper">
    @if (isAuthenticated && currentMenu !== 'login' && currentMenu !== 'logout') {
      <div class="aside-wrapper" @revealAnimation>
        <aside>
          <mat-nav-list class="d-none d-md-block">
            @for (item of navigation; track item) {
              <mat-list-item class="menu-item aside-menu-item" [class.active]="isActive(item)">
                <a [routerLink]="['/' + item.link]" class="aside-menu-item-link">
                  <mat-icon>{{ item.icon }}</mat-icon>
                  {{ item.label | translate }}
                  @if (isActive(item)) {
                    <span class="visually-hidden">({{ 'bkwa.menu.current' | translate }})</span>
                  }
                </a>
              </mat-list-item>
            }
          </mat-nav-list>
        </aside>
      </div>
    }
    <main [@routerAnimation]="prepareRoute(outlet)">
      <div class="view-container">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </main>
  </div>
</mat-sidenav-container>
