import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiCustomError, ApiError, ApiErrorGeneric, ApiErrorNoConnection } from './api/common/api-error';
import { AuthService } from './api/common/auth.service';
import { ErrorsApiService, LoggableError } from './api/errors-api.service';
import { NotificationService } from './pages/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private lastConnectionError = 0;

  public constructor(
    private readonly errorsApi: ErrorsApiService,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly zone: NgZone,
  ) {}

  public handleError(error: unknown): void {
    this.zone.run(() => {
      console.error('Global uncaught error', error);
      if (error instanceof Error) {
        if (/Loading chunk [\d]+ failed/.test(error.message) || /Failed to fetch dynamically imported module/.test(error.message)) {
          window.location.reload();
          return;
        }

        if (!(error instanceof ApiCustomError)) {
          this.errorsApi.insertDelayed(new LoggableError(error, this.authService.getLoginInformation().username || null, 'ERROR'));
        }
        error = GlobalErrorHandler.getInnerError(error);
        if (error instanceof ApiError) {
          if (error instanceof ApiErrorNoConnection) {
            if (Date.now() - this.lastConnectionError > 15000) {
              this.notificationService.error('bkwa.errors.generic.noConnection', true);
              this.lastConnectionError = Date.now();
            }
          } else if (error instanceof ApiCustomError) {
            this.notificationService.customError(error);
          } else {
            if (error instanceof ApiErrorGeneric && error.message && !environment.production) {
              // ocultar errores no tratados por el servidor en PROD
              this.notificationService.error(error.message, false);
            }
            this.notificationService.genericError();
          }
        }
      }
    });
  }

  public static getInnerError(error: Error & { rejection?: Error }): Error {
    return error.rejection ?? error; // unhandled promises
  }
}
